@import "../theme/mixins";

$brand: 'doenerwerk';
$brandAssets: "/assets/#{$brand}";
:root {
  --brand: $brand;
  --ion-color-main: #ACAFAC; ;
  --ion-color-second: #484848;
  --ion-color-card: #222326;
  --ion-color-text: #a5a5a5;
}

@font-face {
  font-family: AppFont;
  src: url('../assets/font/GowunBatang-Regular.ttf')
}
@font-face {
  font-family: AppFontBold;
  src: url('../assets/font/GowunBatang-Bold.ttf')
}
@font-face {
  font-family: Oswald;
  src: url('../assets/font/Oswald-Regular.ttf')
}
@font-face {
  font-family: OswaldLight;
  src: url('../assets/font/Oswald-Light.ttf')
}
@font-face {
  font-family: OswaldBold;
  src: url('../assets/font/Oswald-Bold.ttf')
}

// app-home,
// app-home > ion-content > main {
//   background: var(--ion-color-primary) !important;
// }
