/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/mixins";

@import "./theme/variables.moschmosch";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body {
  font-family: AppFont, sans-serif;
  color: #3F3F3F;
}

app-impressum,
app-tos,
app-scan-qr,
app-privacy {
  ion-header {
    background: var(--ion-color-main);
  }
}

h1, h2, h3, h4, h5 {
  font-family: OswaldBold, sans-serif;
  color: var(--ion-color-main);
  margin: 0;
}

ion-content {
  --background: transparent;
}

.no-text-transform {
  text-transform: none !important;
}

.text-small {
  font-size: small;
}

.text-medium {
  font-size: medium;
}

.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.text-xx-large {
  font-size: xx-large;
}

.ion-page {
  background: #121212;
}

h1 {
  font-size: 26px;
}

.place-items-center {
  place-items: center !important;
}

.white {
  fill: white;
  color: white;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.black {
  fill: black;
  color: black;
}

.flex-1 {
  flex: 1;
}

.relative {
  position: relative;
}

.grey {
  fill: #878787;
  color: #878787;
}

.dark {
  fill: var(--ion-color-black);
  color: var(--ion-color-black);
}

app-venue-suggestion {
  display: block;
}

// app-home,
// app-home > ion-content > main {
//   background-size: cover !important;
//   @include desktop {
//     background-size: cover !important;
//   }
// }


app-sign-in,
app-sign-in > ion-content > main,
app-sign-in-order,
app-sign-in-order > ion-content > main,
app-sign-up,
app-sign-up > ion-content > main,
app-maintenance-page,
app-maintenance-page > ion-content > main
 {
  background: #121212 !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
  ion-header {
    background: #121212 !important;
    --background: #121212 !important;
  }
}

app-account {
  ion-header {
    background: var(--ion-color-main);
  }
}
app-payment-success {
  background:  url('/assets/doenerwerk/home_background_mobile.png')no-repeat center top / 100% !important;
  @include desktop {
    background: url('/assets/doenerwerk/home-desktop.png') no-repeat center top / 100% !important;
  }
  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-check-delivery-radius-modal{
  background: #121212 url('./assets/doenerwerk/check-delivery.png') no-repeat center top / 100% !important;;
  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-multiple-order {
  background:  url('/assets/mado/image-mult.png')no-repeat center top / 100% !important;
  @include desktop {
    background: url('/assets/mado/toolbar-desktop.png') no-repeat center top / 10% !important;
    background-size: 100% 30vh !important;
  }
  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-menu,
app-menu > ion-content  {
  --background: transparent !important;
}
app-menu > ion-header {
  --background: transparent;
  background: transparent
}

.card-bg {
  height: 100%;
  background: #f5f5f5 url(/assets/icon/kreditcartepic.svg) right bottom no-repeat !important;
}

.main-color {
  fill: var(--ion-color-main);
  color: var(--ion-color-main);
}

.second-color {
  fill: var(--ion-color-second);
  color: var(--ion-color-second);
}

.gray {
  fill: var(--ion-color-gray-darkest);
  color: var(--ion-color-gray-darkest);
}

.invisible {
  opacity: 0;
}

.flex {
  display: flex;
}

ion-footer {
  background: var(--ion-color-primary);
  color: #333333;
  font-family: Oswald, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  padding: 20px 10px !important;
  transition: 0.3s linear;
  &::before {
    display: none;
  }

  &:hover {
    transition: 0.3s linear;
    background: var(--ion-color-primary)
  }
}

.ion-footer {
  background: var(--ion-color-primary);
  color: #333333;
  font-family: Oswald, sans-serif;
  font-size: 20px;
  border-radius: 0px;
  text-transform: uppercase;
  padding: 15px 10px !important;
  transition: 0.3s linear;
  text-align: center;
  &:hover {
    transition: 0.3s linear;
    background: var(--ion-color-primary)
  }
}

.ion-footer.invalid {
  background: darkgrey;
  color: grey;

  &:hover {
    background: darkgrey;
    color: grey;
  }
}

.bold {
  font-family: AppFont-bold, sans-serif;
  font-weight: normal;
}
.font-max-bold {
  font-family: AppFont-black, sans-serif;
  font-weight: normal;
}

.menu-row ion-icon .icon-inner svg path {
  fill: red !important;
}

.main-bg.active {
  background: var(--ion-color-main);
}

.second-bg.active {
  background: var(--ion-color-second) !important;
}

.input-group {
  margin-bottom: 10px;
  position: relative;

  .eye-icon {
    position: absolute;
    right: 15px;
    top: calc(50% + 10px);
    transform: translateY(-50%);
    z-index: 9999;
  }

  &__label {
    margin: 0 0 5px 10px;
    font-size: 12px;
    color: white;
  }

  &__input {
    input {
      color: white !important;
      border-radius: 0px !important;
      background: var(--ion-color-card) !important;
      padding: 10px !important;
      font-size: 14px !important;
      height: 50px;
    }
  }
}

.padding-auth {
  padding: 30px 26px !important;
  @include desktop {
    padding: 0 !important;
    height: 100%;
  }
}

.auth-wrapper {
  width: 100%;
  @include desktop {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.product-info {
  .action-sheet-group {
    height: 300px;
    padding: 32px 30px !important;

    .action-sheet-title {
      font-family: AppFont-bold, sans-serif;
      color: #333333;
      font-size: 26px;
      padding: 0;

      .action-sheet-sub-title {
        font-family: AppFont, sans-serif;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.auth-container {
  > ion-col {
    display: block;
  }

  @include desktop {
    width: 400px;
    margin: 0 auto;
    padding: 32px 40px;
    background: transparent;
    border-radius: 0;
  }
}

ion-fab-button {
  --background: transparent !important;
}

.subway-btn {
  width: 100%;
  padding: 18px;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 2px solid var(--ion-color-primary);
  font-family: Oswald, sans-serif;

  &.filled {
    background: var(--ion-color-primary);
    color: #222326;
    transition: 0.3s linear;

    &:hover {
      transition: 0.3s linear;
      background: var(--ion-color-primary)
    }
  }

  &.flat {
    background: #ffffff;
    color: var(--ion-color-primary);
    transition: 0.3s linear;

    &:hover {
      transition: 0.3s linear;
      background: #e3e3e3;
    }
  }
}

.hours-modal {
  @include desktop {
    ion-backdrop {
      background: #000000 !important;
    }
  }
}

.modal-wrapper {
  min-height: 85vh !important;
  @include desktop {
    --width: 450px
  }
}

.product-modal {
  .modal-wrapper {
    @include desktop {
      width: 450px !important;
      height: auto !important;
    }
  }
}

.hours-modal .modal-wrapper {
  height: calc(100% - 65px);
  bottom: 0;
  position: absolute;
  display: block;

  @include desktop {
    height: 600px;
    width: 480px;
    position: unset;
    bottom: unset;
  }
}

.short-info-modal {
  background: rgba(0, 0, 0, 0.2);
}

.short-info-modal .modal-wrapper {
  @include desktop {
    width: 350px;
  }
  @include mobile {
    width: 95vw;
  }
}

.item-interactive.ion-valid {
  --highlight-background: var(--ion-color-main);
}

.ion-color-white {
  color: #fff;
}

.shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.shadow-small {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.google-search-input {
  input {
    font-size: 15px !important;
    padding: 20px 0 !important;
  }
}

.menu-popover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  .popover-content {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    @include desktop {
      width: 315px;
      height: auto;
      min-height: auto;
      top: 75px !important;
      left: unset !important;
      right: 12px !important;
    }
  }

  .popover-arrow {
    right: 20px !important;

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
    }
  }
}

.home-popover {
  font-family: AppFont-bold, sans-serif;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.5) !important;
  .popover-content {
    width: 285px;
    border-radius: 0;
    left: 50px !important;
    top: 58px !important;
    background: var(--ion-color-card) !important;
    @include desktop {
      top: 68px !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto;
    }
    @media screen and (max-width: 320px) {
      width: 250px;
    }
  }

  .popover-arrow {
    left: 70px !important;
    top: 50px !important;

    @include desktop {
      left: -220px !important;
      top: 60px !important;
      right: 0;
      margin: auto;
    }

    &:after {
      background: var(--ion-color-card) !important;
      border-radius: 0 !important;
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.step-heading {
  font-family: Oswald, sans-serif;
  font-size: 24px;
  text-align: left;
  color:white;
  margin: 35px 0 15px;
  width: 100%;
  font-weight: 800;
  text-transform: uppercase;
}

.small-padding {
  .step-heading {
    font-size: 16px;
    margin: 0 0 5px;
  }
}


.position-relative {
  position: relative !important;
}

.height-auto {
  height: auto !important;
}

.pac-container {
  background: white;
  width: 100%;
  border-radius: 0px;
  padding: 8px 20px !important;
  margin-top: 5px;
  border: none !important;

  .pac-item {
    border-top: none !important;
    font-family: AppFont, sans-serif;
    padding: 16px 0;
    line-height: 1 !important;
    font-size: 14px !important;
    text-align: left !important;
    &:last-child {
      border-bottom: none;
    }

    .pac-icon {
      display: none !important;
    }

    .pac-matched {
      font-family: AppFontBold, sans-serif;
    }
  }
}

.preorder-type {
  background: var(--ion-color-secondary);
  font-size: x-small;
  height: auto;
  margin: 0 0 0 10px;
  padding: 2px 4px;
  color: #fff;
  border-radius: 4px;
}

ion-checkbox {
  --background-checked:var(--ion-color-primary);
  --border-color-checked: var(--ion-color-primary);
  --checkmark-color: #212121;
}

app-toolbar {
  min-height: 70px;
}

app-toolbar-small {
  min-height: 70px;
}

.toolbar-title {
  font-family: AppFont-black, sans-serif;
  padding-left: 65px;
  text-align: left;
}

.pointer {
  cursor: pointer !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
  input {
    cursor: pointer !important;
  }
}

.auth-padding-small {
  padding: 22px 26px;
  position: relative;
  height: 100%;

  @include desktop {
    padding: 0;
  }

  h1 {
    font-size: 26px;
    line-height: 120%;
    margin: 0 0 15px;
  }

  p {
    font-size: 14px;
    line-height: 145%;
    margin: 0;
  }

  .subway-btn {
    position: absolute;
    width: calc(100% - 52px);
    left: 26px;
    bottom: 26px;
    margin: 0;
  }
}

ion-row.center {
  justify-content: center;
  height: 100%;
  place-content: center;
}

ion-item > ion-label {
  white-space: unset !important;
  overflow: unset !important;
}

.split-pane-side {
  max-width: 100% !important;
  flex: auto !important;
  flex-direction: column !important;
}

.cdk-overlay-pane {
  bottom: 0;
}

app-article-option-group-overlay {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: var(--ion-color-secondary) url(#{$brandAssets}/menu_background.png) no-repeat center center/contain;
  background-origin: content-box;
  padding: 16px;

  .wrapper-upgrade {
    height: 750px;
  }

}

.display-contents {
  display: contents;
}

.display-grid {
  display: grid;
}

.label-floating {
  z-index: 4;
}

app-burger-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  cursor: pointer;
}

.underline {
  color: var(--ion-color-gold);
  text-decoration: underline;
}

ion-picker {
  touch-action: none;
}

.cc-animate {
  transition: transform 0.3s ease-in-out !important;
}

.cc-revoke {
  transform: translateY(100%) !important;
}

.cc-active.cc-revoke {
  transform: translateY(0) !important;
}

svg {
  width: 100%;
}

.picker-opt {
  opacity: 0.3;
  font-weight: bold;
}

.picker-opt-selected {
  opacity: 1;
}

@include desktop {
  ion-picker-column {
    overflow: auto;
  }
  .picker-opts {
    top: unset !important;
    transform: none !important;
    pointer-events: none;
  }
  .picker-opt {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    transform: none !important;
  }
  .picker-opt-selected {
    transition: 100ms;
    font-size: 30px;
  }
}

.delivery-not-available-modal .modal-wrapper {
  height: 260px;
  min-height: unset !important;
  bottom: 0;
  position: absolute;
  display: block;
  @include desktop {
    height: 220px;
    bottom: unset !important;
  }
}

.flex-grow {
  display: flex;
  flex-grow: 1;
}

.align-center {
  text-align: center;
}

.searchbar-input.sc-ion-searchbar-md {
  box-shadow: unset !important;
  border-radius: 0px !important;
  background:  #222326;
  --background:  #222326;
  min-height: 50px;
}
.searchbar-search-icon {
  color: var(--ion-color-primary) !important;
  top: 15px !important;
}
ion-header:after {
  display: none;
}

app-my-orders {
  ion-header {
    background: var(--ion-color-main);
  }
}
 ion-modal {
   ion-backdrop {
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(6px);
    --backdrop-opacity: 1;
   }
 }
ion-modal.auto-height {
  background: rgba(0, 0, 0, 0.2);
  --height: auto;

  .modal-wrapper {
    min-height: unset !important;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 95vh;

      .modal-content {
        overflow: auto;
      }
    }
  }
}

ion-modal.align-bottom {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
  }
}

ion-modal.align-bottom-only-mobile {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
    @include desktop {
      bottom: unset !important;
    }
  }
}

ion-modal.allow-gps-modal .modal-wrapper {
  @include mobile {
    width: 95vw;
  }
}
.only-next-day-modal  .modal-wrapper {
  @include mobile {
    width: 95vw;
  }
}
.short-info-modal .modal-wrapper {
  @include mobile {
    width: 95vw;
  }
}
.select-venue-modal .modal-wrapper  {
  @include mobile {
    width: 95vw;
  }
}

.light-grey-transparent {
  background: rgba(0, 0, 0, 0.2);
  transition: 0.1s linear;
}

ion-item {
  --background-focused-opacity: 0;
}

ion-item {
  --ripple-color: transparent !important;
  --background-hover: transparent !important;
}

app-promo-code {
  display: block;
}


.sidebar {
  ion-slides {
    display: contents;

    > div {
      display: contents !important;
    }
  }

  ion-slide {
    display: contents;
  }
}


.wpwl-group-button {
  display: flex;
  place-content: center;
  flex-direction: row;
}

.wpwl-wrapper {
  width: 100%
}

.wpwl-control {
  background: #FAFAFA;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.wpwl-label {
  width: 100%;
  font-size: 13px;
  padding: 4px 10px;
}

.wpwl-wrapper-brand {
  width: 80%;
}

.wpwl-brand-card {
  width: 20%;
}

.wpwl-sup-wrapper-state {
  display: none;
}

.wpwl-sup-wrapper-street2 {
  display: none;
}

.cc_dialog {
  width: 100% !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  padding: 16px !important;
  background: var(--ion-color-card)  !important;

  p {
    font-size: 10px !important;
    color: var(--ion-color-main) !important;
  }

  h1 {
    font-size: 16px;
    font-family: Oswald, sans-serif !important;
    color: white !important;

  }
}

.cc_cp_f_powered_by {
  display: none !important;
  content: none !important;
}

.cc_cp_m_content_entry {
  height: auto !important;
  max-height: 500px !important;
  overflow: auto !important;
}

.checkbox_cookie_consent {
  display: none;
}

.cc_cp_f_save {
  button {
    background: var(--ion-color-main) !important;
    color: white !important;
  }
}

.cc_b_cp {
  border-radius: 0px !important;
  color: #212121 !important;
  border: 2px solid var(--ion-color-primary) !important;
  background-color: var(--ion-color-primary)  !important;
  font-family: Oswald, sans-serif !important;
  font-size: 16px !important;
  text-transform: uppercase;
}

.cc_b_ok {
  color: var(--ion-color-primary) !important;
  background: transparent !important;
  border: 2px solid var(--ion-color-primary) !important;
  border-radius: 0px !important;
  font-family: Oswald, sans-serif !important;
  font-size: 16px !important;
  text-transform: uppercase;


}


app-add-to-home {
  position: absolute;
  bottom: 0;
}

ion-row.center-vertical {
  > * {
    align-self: center;
  }
}

.dashed-border {
  border: 2px dashed var(--ion-color-primary);
}


.tip-picker {
  .picker-columns {
    width: 30%;
    min-width: 200px;
    margin: 0 auto;

    ion-picker-column > .picker-opts > .picker-opt-selected {
      font-size: 25px;
      color: var(--ion-color-primary);
    }

    .sign-column {
      width: 30px;
    }

    .comma-column {
      width: 30px;
    }
  }
}

.padding-5 {
  padding: 5px;
}

.max-height-100 {
  max-height: 100%;
}

.content-only {
  color: var(--ion-color-primary);
  width: auto;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0 5px;
  --padding-start: 0;
  --padding-end: 0;
  --box-shadow: none;
  --background: none;
}


app-scan-qr {
  background: transparent;
  backdrop-filter: blur(20px);
}
app-order > ion-content  {
  background: transparent;
  --background: transparent

}
app-order-content > ion-content  {
  background:transparent;
  --background: transparent

}
app-modal-info{
  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
  
}

app-menu-modal {
  background: var(--ion-color-secondary) url('./assets/doenerwerk/home_background_mobile.png')  no-repeat center bottom / 100% !important;
  background-size: cover !important;
  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-note-article-modal{
  background: #222326 !important;
}
app-email-confirmation,
app-email-action {
  background: url('#{$brandAssets}/modal-circle.svg') no-repeat right bottom / 30% !important;
}
app-payment-modal{ 
  ion-content {
    --background: transparent !important;
  }
  h1 {
    margin-left: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
    color: var(--ion-color-main) !important;
    font-family: AppFont, sans-serif;
    font-weight: 500;
    margin-top: 30px !important;
    text-transform: uppercase !important;
  }
  .payment-input {
    --payment-modal-item-background: var(--ion-color-card) !important;
    border-radius: 0px !important;
    background-color: var(--ion-color-card);
  }
  .wpwl-label {
    color: white;
    font-family: AppFont, sans-serif;
  }
  .wpwl-form {
    background: var(--ion-color-card);
    padding: 10px;
  }
  .wpwl-wrapper {
    border-radius: 0px !important;
    overflow: hidden;
    background-color: var(--ion-color-card);

  }
  .wpwl-control {
    border-radius: 0px !important;
    overflow: hidden;
    background-color: #F8F8F8;

  }
  iframe {
    background: #F8F8F8;
  }
  ion-item {
    border: none;
    --background: var(--ion-color-card) !important;
    box-sizing: border-box;
    border-radius: 0px;
    ion-label {
      font-family: AppFont, sans-serif !important;
      color: white !important;
    }
    ion-icon {
      zoom: 2.0 !important;
    }
  }
  .payment-method-row {
    background: var(--ion-color-card) !important;
    border-radius: 0px !important;
  }
  .wpwl-button-pay{
    float: none !important;
    width: 100%;
    background: var(--ion-color-primary);
    border-radius: 0px;
    background-color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
    font-family: AppFontBold, sans-serif;
    text-transform: uppercase;
    color: #121212;
    font-family: Oswald, sans-serif;
  }
  ion-radio {
    --color: var(--ion-color-primary) !important;
  }
}
.close-icon{
  zoom: 0.6 !important;
}
app-map {
  background: var(--ion-color-secondary);
  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-home {
  background: var(--ion-color-secondary) url(#{$brandAssets}/home-desktop.png) no-repeat center top / 100% !important;
  background-size: cover !important;

  @include mobile {
    background: var(--ion-color-secondary) url(#{$brandAssets}/home_background_mobile.png) no-repeat center top / 100% !important;
  }

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-image-toolbar {
  background: url(#{$brandAssets}/home-back-desktop1.jpg) no-repeat center top / 10% !important;
  background-size: cover !important;
}
#search-container {
 background: transparent !important;
}
.map-toggle-container {
  background: var(--ion-color-secondary) !important;
  .mode-icon {
    background: #232622 !important;
    color: var(--ion-color-primary) !important;
  }
  .mode-icon.selected {
    background:var(--ion-color-primary) !important;
    color: #232622 !important;
  }

}
.map-toggle {
  .title {
    margin-top: 0px !important;
  }
  background: #121212  !important;

}
.block-venues {
  background: #121212  !important;

}
#search-container-input {
  width: 100%;
  padding: 10px 20px;
  @include desktop{
    padding: 20px 50px;

 }
 app-map-search-venue {
   width: 100%;
   ion-item {
     --background: var(--ion-color-card) !important;
     --color: white !important;
     ion-icon {
       fill: var(--ion-color-primary);
     }
   }
 }
}

.cluster {
  display: flex;
  justify-content: center;

  > img {
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    content: url(/assets/icon/maps_marker_qunatity.svg);
  }

  > div {
    display: contents;
    line-height: 2rem !important;

    > span {
      z-index: 2;
      color: black;
      font-family: AppFontBold, "Helvetica Neue", sans-serif !important;
      font-size: 15px;
    }
  }
}